$primary-color: #297DF2;
$primary-color-dark: #193676;
$bg-color: #E8F3FE;
$bg-gray: #F2F2F2;


@import '~bootstrap/scss/bootstrap.scss';
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;400;600&display=swap');

@keyframes pop{
  50%  {transform: scale(1.04);}
}

html {
	background-color: transparent !important;
}
body {
	background-color: transparent !important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


html, body, p, h1,h2,h3,h4,h5,h6, li {
	color: #19234F;
}

.unify-interact-wrapper {
	position: fixed;
    bottom: 0;
    right: 0;
    height:100%;
}

.demo-image {
	width: 100%;
}

.hidden {
	visibility: hidden !important;
	opacity: 0;
}
.unify-launcher {
	overflow: hidden;
	width: 60px;
	height: 60px;
	border-radius: 50%;
	border: 2px solid $primary-color-dark;
	filter: drop-shadow(0px 4px 20px rgba(8, 23, 44, 0.1));
	background-color: $primary-color-dark;
	display: flex;
	align-items: center;
  	justify-content: center;
	position: absolute;
	right: 25px;
	bottom: 25px;

	transition: all .2s ease-in-out;
	&:hover, &.active {
		cursor: pointer;
		transform: scale(1.1);
	}


	.avatar {
		width: 60px;
		height: 60px;
		transition: all .2s ease-in-out;
		z-index: 100;
	}

	.close {
		position: absolute;

		color: white;
		height: 18px;
		width: 18px;
		z-index: 0;
		filter: invert(100%) sepia(0%) saturate(7455%) hue-rotate(91deg) brightness(109%) contrast(107%);
	}

	&.chat-active {
		.avatar {
			width: 0px;
			height: 0px;
		}
	}
}

.unify-launcher-options {

	width: 290px;
	border-radius: 20px;
	background-color: white;
	box-shadow: 0px 4px 20px rgba(8, 23, 44, 0.1);
	padding: 24px 15px;

	position: absolute;
	right: 25px;
	bottom: 100px;
	transition-delay: 250ms;
	animation: pop 0.3s linear 1;

	.greeting {
		margin-left: .5rem;
		position: relative;
	}
	.close {
		width: 40px;
		height: 40px;
		border-radius: 50%;
		position: absolute;
		background-color: $bg-color;
		right: -10px;
		top: -45px;
		visibility: visible;
		transition: all .2s ease-in-out;
		&:hover {
			cursor: pointer;
		}
	}

	.option {
		margin-top: 12px;
		font-size: .8rem;
		border-radius: 20px;
		padding: 10px 16px;
		background-color: $bg-color;

		&:hover {
			cursor: pointer;
			background-color: $primary-color;
			color: white;
		}
	}


}



.unify-chat-wrapper {

	overflow: hidden;
	position: absolute;
	right: 25px;
	bottom: 100px;
	height: 0px;
	width: 0px;
	background-color: white;
	transition: all .3s ease-in-out;
	box-shadow: 0px 4px 20px rgba(8, 23, 44, 0.1);
	border-radius: 18px;
	visibility: hidden;
  	opacity: 0;
  	display: flex;
  	flex-direction: column;

	&.active{
		width: 340px;
		height: min(700px, 100% - 125px);
		visibility: visible;
  		opacity: 1;
	}

	>div {
		overflow: hidden;
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		position: relative;
	}

	.unicom-form {
		label {
			text-transform: uppercase;
			font-size: .8rem;
			margin-top: 10px;
			width: 100%;
			input {
				margin-top: 5px;
				display: block;
				width: 100%;
				border-radius: 14px;
				border: none;
				background-color: white;
					font-weight: 400;
					font-size: 14px;
					line-height: 21px;
					/* identical to box height, or 150% */
					border: none;
					letter-spacing: 0.25px;
					padding: 10px 10px;

			}
			select {
				margin-top: 5px;
				display: block;
				width: 100%;
				border-radius: 14px;
				border: none;
				background-color: white;
					font-weight: 400;
					font-size: 14px;
					line-height: 21px;
					/* identical to box height, or 150% */
					border: none;
					letter-spacing: 0.25px;
					padding: 10px 10px;
			}
		}
	}

	.eligibility-wrapper {
		animation: pop 0.3s linear 1;
		position: absolute;
		top: 14px;
		right: 14px;
		left: 14px;
		bottom: 14px;
		border-radius: 15px;
		background-color: #efefef;
		overflow: hidden;
		display: flex;
		
		>div {
			flex-grow: 1;
			overflow-y: auto;
			padding: 20px;
			position: relative;
			.title {
				margin-top: 14px;
				font-weight: 600;
				font-size: 1.1rem;
				text-transform: uppercase;
				margin: 0;
				margin-bottom: 15px;
			}
			.trigger-list-close {
				border: none;
				width: 40px;
					height: 40px;
					border-radius: 50%;
				color: darkgray;
				transition: all .3s ease-in-out;
				position: absolute;
				top: 8px;
				right: 8px;
				font-size: 30px;
				line-height: 1px;
				padding: 0px;
				&:hover {
					cursor: pointer;
					background-color: #cdcdcd;
					transform: scale(1.15);
				}
			}
			button.submit, button[type="submit"] {
				margin-top: 28px;
				padding: 10px 18px;
					background-color: $primary-color;
					color: white;
					border-radius: 30px;
					border: none;
					width: 100%;
					margin-bottom: 20px;
					&:disabled {
						background-color: #efefef;
    				cursor: not-allowed;
    				color: rgb(84, 84, 84);
					}
			}
		}
	}

	.trigger-list-wrapper {
		animation: pop 0.3s linear 1;
		position: absolute;
		top: 14px;
		right: 14px;
		left: 14px;
		bottom: 14px;
		border-radius: 15px;
		background-color: #efefef;
		overflow-y: auto;
		>div {

			position: relative;
			display: flex;
			flex-direction: column;
			.trigger-list-close {
				border: none;
				width: 40px;
					height: 40px;
					border-radius: 50%;
				color: darkgray;
				transition: all .3s ease-in-out;
				position: absolute;
				top: -6px;
				right: -6px;
				font-size: 30px;
				line-height: 1px;
				padding: 0px;
				&:hover {
					cursor: pointer;
					background-color: #cdcdcd;
					transform: scale(1.15);
				}
			}
		}
		ul {
			.title {
				margin-top: 14px;
				font-weight: 600;
				font-size: .8rem;
				text-transform: uppercase;
			}
		}
		padding: 20px;

		.interaction {
			margin-top: 10px;

			padding: 10px 16px;
			border: 1px solid #B6C2E0;
			border-radius: 50px;
			font-weight: 400;
			font-size: 14px;
			line-height: 21px;
			float: left;
			&:hover {
				color: white;
				background-color: $primary-color;
				border-color: $primary-color;
				cursor: pointer;
			}
		}
	}

	.chat-video-wrapper {
		position: absolute;
		top: 0;
		right: 0;
		left: 0;
		bottom: 0;
		background-color: black;
		display: flex;
		
		>div {
			flex-grow: 1;
			display: flex;
			flex-direction: column;
			align-items: center;
  		justify-content: end;
			.video-controls {
				z-index: 100000;
				position: absolute;
				top: 0;
				right: 0;
				left: 0;
				padding: 10px;
				display: flex;
				flex-direction: row;
				background-color: white;
				.video-controls-btn {
					margin-right: 5px;
					margin-left: 5px;
					border: none;
					background: white;
					width: 50px;
					height: 50px;
					border-radius: 50%;
					color: #ababab;
					transition: all .3s ease-in-out;
					border: 1px solid #ededed;
					font-size: 30px;
    			line-height: 0px;
					&:hover {
						background-color: #ededed;
						transform: scale(1.1);
					}
					&.close {
						border: 1px solid transparent;
						align-self: end;
						margin-left: auto;
					}
				}
			}
		}
	}

	.unify-chat-header {
		background-color: $primary-color;
		position: relative;
		padding: 36px 24px 12px;
		&.unify-chat-header--small {
			min-height: 88px;
			overflow: hidden;
			padding: 20px 24px;
			display: flex;
			flex-direction: row;
			align-items: flex-end;
			.avatar {
				height: 40px;
				width: 40px;
			}
			.sub-greeting {
				display: none;
			}
			.greeting { 
				margin-left: 1rem;
			}
			.ornament {
				display: none;
			}
		}
		.ornament {
			position: absolute;
			top: 0px;
			right: 0px;

		}
		.avatar {
			height: 80px;
			width: 80px;
			border-radius: 50%;
			
		}
		.greeting {
			margin-top: 1rem;
			margin-bottom: .5rem;
			font-size: 20px;
			color: white;
		}
		.sub-greeting {
			font-size: 14px;
			line-height: 21px;
			color: white;
		}
	}

	.unify-chat {
		padding: 14px;
		overflow-y: auto;
		ul > li {
			margin-bottom: 12px;
			display: inline-block;
		}
		.chat-text {
			
			
			font-size: 14px;
			line-height: 21px;
			padding: 12px 20px;
			/* or 150% */
			width: auto;
			animation: pop 0.3s linear 1;
			letter-spacing: 0.25px;
			max-width: 88%;
			&.assistant {
				align-self: flex-start;
				background: $bg-gray;
				border-radius: 0px 10px 10px 10px;
				&.writing {
					position: relative;
					float: left;
					width: 50px;
					height: 34px;
				}
			}
			&.user {
				align-self: flex-end;
				background: $primary-color;
				color: white;
				border-radius: 10px 10px 0px 10px;
			}

			.chat-form {
				margin-top: 20px;
				input {
					border-radius: 14px;
					background-color: white;
					font-weight: 400;
					font-size: 14px;
					line-height: 21px;
					/* identical to box height, or 150% */
					border: none;
					letter-spacing: 0.25px;
					padding: 10px 10px;
					width: 100%;
					margin-bottom: 10px;
					&:disabled {
						background-color: #efefef;
    				cursor: not-allowed;
					}
				}
				button.submit {
					padding: 10px 18px;
					background-color: $primary-color;
					color: white;
					border-radius: 30px;
					border: none;
					float: right;
					width: 100%;
					&:hover {
						background-color: $primary-color-dark !important;
					}
					&:disabled {
						background-color: #efefef;
    				cursor: not-allowed;
    				color: rgb(84, 84, 84);
    				&:hover {
    					background-color: #efefef !important;
    				}
					}
					
				}
			}


			.chat-btn {
					padding: 6px 16px;
					background-color: white;
					border: 1px solid #B6C2E0;
					border-radius: 50px;
					font-weight: 400;
					font-size: 14px;
					line-height: 21px;
					width: 100%;
					margin-top: .8rem;
					float: right;
					&:hover {
						color: white;
						background-color: $primary-color;
						border-color: $primary-color;
						cursor: pointer;
					}
					&.disabled {
						background-color: #efefef;
						border-color: #dedede;
						&:hover {
							cursor: not-allowed;
							border-color: #dedede;
							background-color: #efefef;
							color: black;
						}
					}
				}

				.link-card-container
				{
					background-color: #DADADA;
					overflow: hidden;
					width: 100%;
					display: flex;
					flex-direction: row;
					border-radius: 5px;
					text-decoration: none;
					max-height: 80px;
					border: 2px solid #efefef;
					margin-top: 10px;
					&:hover {
						border: 2px solid $primary-color;
						cursor:pointer;
					}
					h6, p {
						text-decoration: none;
						text-overflow: ellipsis;
						
					}
					p{max-height: 20px}
					figure {
						display: flex;
						position: relative;
						max-width: 80px;
						max-height: 80px;
						overflow: hidden;
						align-items: center;
						justify-content: center;
						margin: 0;
						img {
							height: 100%;
						}
					}
					.link-content {
						flex-grow: 1;
						max-width: 120px;
						padding: 10px;
						h6{
							max-height: 36px;
							font-weight: 500;
							font-size: 14px;
							line-height: 18px;
							overflow: hidden;
							margin-bottom: 4px;
						}
						p {
							white-space: nowrap;
							overflow: hidden;
						}
					}
				}

			.chat-video-container {
				width: 100%;
				margin-top:.8rem;
				overflow: hidden;
				position: relative;
				border-radius: 14px;
				border: 1px solid transparent;
				.video-thumbnail {
					width: 100%;
				}
				.play {
					position: absolute;
					top: 50%;
					left: 50%;
					width: 40px;
					margin-left: -20px;
					margin-top: -20px;
					transition: all .3s ease-in-out;
				}

				&:hover {
					border: 1px solid $primary-color;
					cursor: pointer;
					.play {
						transform: scale(1.2);
					}
				}
			}
		}

		.interaction {
			align-self: flex-end;
			padding: 10px 16px;
			border: 1px solid #B6C2E0;
			border-radius: 50px;
			font-weight: 400;
			font-size: 14px;
			line-height: 21px;
			float: right;
			&:hover {
				color: $primary-color;
				background-color: $bg-color;
				cursor: pointer;
			}
		}

		.spinner { 
			position: absolute;
			
			top: 50%;
			left: 50%;

			width: 45px;
			height: 9px;

			margin-left: -22px;
			margin-top: -13px;

			text-align: center;
		}
		.spinner > div { 
			width: 9px;
			height: 9px;
			background-color: #dcdcdc;
			border-radius: 100%;
			display: inline-block;
			-webkit-animation: bouncedelay 1.4s infinite ease-in-out;
			animation: bouncedelay 1400ms ease-in-out infinite;
			/* Prevent first frame from flickering when animation starts */ -webkit-animation-fill-mode: both;
			animation-fill-mode: both;

		}
		.spinner .bounce1 { 
			-webkit-animation-delay: -0.32s;
			animation-delay: -0.32s;
		}
		.spinner .bounce2 { 
			-webkit-animation-delay: -0.16s;
			animation-delay: -0.16s;
		}
		@mixin bouncedelay() {
			0%, 80%, 100% {
				transform: scale(0.0);
				-webkit-transform: scale(0.0);
			}
			40% {
				transform: scale(1.0);
				-webkit-transform: scale(1.0);
			}
		}
		@-webkit-keyframes bouncedelay {
			@include bouncedelay();
		}
		@keyframes bouncedelay {
			@include bouncedelay();
		}

		.program-summary {
			padding: 24px;
			background: #FFFFFF;
			border: 1px solid #B6C2E0;
			border-radius: 8px;

			.program-summary-header {
				display: flex;
				flex-direction: row;
				padding-bottom: 12px;
				border-bottom: 1px solid #B6C2E0;
				.logo-wrapper {
					margin-right: 1rem;
					overflow: hidden;
					border-radius: 5px;
					width: 46px;
					height: 46px;
					display: flex;
					align-items: center;
				  	justify-content: center;
					img {
						width: 100%;
					}
				}
				.program-name {
					font-weight: 600;
					font-size: 13px;
					line-height: 24px;
					margin: 0;
					letter-spacing: 0.15px;
				}
				.university-name {
					font-weight: 400;
					font-size: 12px;
					line-height: 21px;
					margin: 0;
					letter-spacing: 0.25px;
				}
			}
		}
		.program-summary-content {
			padding-top: 18px;
			.content-card {
				width: 50%;
				padding: 8px;
				.icon {
					margin-right: .6rem;
				}
				font-size: 14px;
				line-height: 21px;
				/* identical to box height, or 150% */

				letter-spacing: 0.25px;

				color: #000000;
			}
		}
	}

}